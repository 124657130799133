import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';

export const squareImage = graphql`
    fragment squareImage on File {
        childImageSharp {
            fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

const TalksPage = ({ data }) => {
    return (
        <Layout>
            <br />
            <p><b>Talks</b></p>
            <div>
                <p>
                    2019, Regularization in directable environments with application to Tetris , <i>Invited talk, CORMSIS seminar, Southampton, UK</i>
                </p>
                <p>
                    2019, A gentle introduction to Q-learning in Python, <i>PyData Meetup, Bristol, UK</i>
                </p>
                <p>
                    2019, Regularization in directable environments with application to Tetris , <i>ICML, Long Beach, CA</i>
                </p>
            </div>
        </Layout>
    );
};

export default TalksPage;
